.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  .brand {
    cursor: pointer;
    & > span::before {
      content: '';
      position: absolute;
      $size: 40px;
      top: 5px;
      left: 5px;
      display: inline-block;
      background: #2d2d2d url('../Home/logo.png') no-repeat center center;
      width: $size;
      height: $size;
      background-size: 80%;
      margin: 0 10px 0 0;
      border-radius: $size / 2;
    }
  }
  nav :global(.fa) {
    font-size: 2em;
    line-height: 20px;
  }
}

* {
  margin: 0;
}
html, body {
  height: 100%;
}

.appContent {
  flex-grow: 1;
  position: relative;
  background-color: white;
}

.footer {
  flex-shrink: 0;
  padding: 8px;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  & > p {
    margin: 0;
  }
}

:global(.form-control-feedback) {
  text-align: inherit;
}
