@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

/**
 *  Define scss variables here.
 *
 *  Available options for Bootstrap:
 *  http://getbootstrap.com/customize/
 *
 */

// Custom Colors
$cyan: #33e0ff;
$humility: #777;

// Bootstrap Variables
$brand-primary: darken(#428bca, 6.5%);
$brand-secondary: #e25139;
$brand-success: #5cb85c;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;
$brand-info: #5bc0de;

$text-color: #333;

$font-size-base: 14px;
$font-family-sans-serif: "Helvetica Neue", Helvetica, sans-serif;
